


import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { GlobalState } from '../../../../store/types';
import { useSelector } from 'react-redux';

// Define the theme with styleOverrides
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '13px', 
          backgroundColor: 'rgba(0, 0, 0, 0.7)',  
          padding: '8px 12px',  
          color: 'white',  
          borderRadius: '4px',  
          zIndex: 9999,  
        },
        arrow: {
          color: 'rgba(0, 0, 0, 0.7)',  // Tooltip arrow color
        },
      },
    },
  },
});



// Define ticket props type
interface TicketProps {
  ticketNumber: string;
  status: string
  timestamp: Date;
  resolution: string;
}
// TicketRow Component
const TicketRow = ({ ticketNumber, status, timestamp, resolution }: TicketProps) => {
  const getStatusColor = (status: string) => {
    return status.toLowerCase() === 'open' ? '#61caff' : 'green';
  };

  function formatTimestamp(timestamp: string | number | Date): string {
    const now = new Date();
    const time = new Date(timestamp);
    const differenceInSeconds = Math.floor((now.getTime() - time.getTime()) / 1000);
  
    const minutes = Math.floor(differenceInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else {
      return `${days}d ago`;
    }
  }

  const isResolved = status.toLowerCase() === 'resolved';
  const tooltipContent = resolution ? resolution : <i>Waiting for customer support resolution...</i>;

  return (
    <ThemeProvider theme={theme}>
    <Tooltip title={tooltipContent} arrow placement="top"
    PopperProps={{
      modifiers: [
        {
          name: 'zIndex',
          enabled: true,
          phase: 'write',
          fn: ({ state }) => {
            state.styles.popper.zIndex = '9999'; // Set high z-index value
          }
        }
      ]
    }}
    >
      
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 15px',
          borderRadius: '8px',
          margin: '15px',
          backgroundColor: '#f9f9f9',
          boxShadow: '2px 4px 10px 5px rgba(0, 0, 0, 0.1)',
          opacity: isResolved ? 0.2 : 1,
          cursor: 'pointer', // Add cursor to indicate hover effect
          position: 'relative',
        }}
      >
        <div>
          <div style={{ display: 'flex', padding:'5px 0px' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{ticketNumber}</div>
            <div style={{ textAlign: 'left', fontSize: '12px', color: '#688', alignContent: 'center' }}>
              &nbsp;&nbsp;{formatTimestamp(timestamp)}
            </div>
          </div>
          <div style={{ textAlign: 'left', fontSize: '13px', color: '#666' }}>{status}</div>
        </div>
        <div
          style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: getStatusColor(status),
          }}
        ></div>
      </div>
    </Tooltip>
    </ThemeProvider>
  );
};

const TicketList = () => {
  const { tickets } = useSelector((state: GlobalState) => ({
    tickets: state.tickets.tickets
  }));
  const sampleRes = "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...\"There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...\"";
  return (
    <div style={{ margin: 'auto auto' }}>
      {tickets.map((ticket, index) => (
        <TicketRow
          key={index}
          ticketNumber={ticket.ticketNumber}
          status={ticket.status}
          timestamp={ticket.timestamp}
          resolution={ticket.resolution}
        />
      ))}
    </div>
  );
};

export default TicketList;
