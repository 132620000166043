
import React from 'react';
import './style.scss';
const close = require('../../../../../../../assets/clear-button.svg') as string;

type Props = {
  showTickets: boolean;
  title: string;
  subtitle: string;
  toggleChat: () => void;
  showCloseButton: boolean;
  titleAvatar?: string;
  toggleTickets: () => void;
}

function Header({ showTickets, title, subtitle, toggleChat, showCloseButton, titleAvatar, toggleTickets }: Props) {
  return (
    <div className="rcw-header">
      {showCloseButton &&
        <button className="rcw-close-button" onClick={toggleChat}>
          <img src={close} className="rcw-close" alt="close" />
        </button>
      }
        <button
        onClick={toggleTickets}
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          color: '#35cce6',
          fontWeight: 'lighter',
        }}
      >
        <span style={{ transform: `rotate(${showTickets ? 180 : 0}deg)`, display: 'inline-block', marginRight: '5px' }}>&#9664;</span>
        {showTickets ? 'Chat' : 'Tickets'}
      </button>
      <h4 className="rcw-title">
        {titleAvatar && <img src={titleAvatar} className="avatar" alt="profile" />}
        {title}
      </h4>
      <span>{subtitle}</span>
    </div>
  );
}

export default Header;
