import React, { useState } from "react";
import "./style.scss";
import { AnyFunction } from "../../../../utils/types";

export type FeedbackPayload = {
  resolved: boolean;
  score: number;
  comment: string;
};

type Props = {
    onSubmitFeedback?: AnyFunction;
  };

const Feedback = ({onSubmitFeedback}: Props) => {
  const [resolved, setResolved] = useState<boolean | null>(null); // Track if liked or disliked
  const [score, setScore] = useState<number>(0); // Track score from 1-5
  const [comment, setComment] = useState<string>(""); // Track user comment
  const [step, setStep] = useState<number>(1); // Step in the feedback process

  const handleSubmit = () => {
    const payload: FeedbackPayload = {
      resolved: resolved ?? false,
      score,
      comment,
    };
    console.log("Feedback Payload:", payload);
    onSubmitFeedback(payload);
  };

  return (
    <div className="feedback-container">
      {step === 1 && (
        <div className="feedback-step">
          <h3>Has Evie helped you with your concern?</h3>
          <div className="feedback-buttons">
            <button onClick={() => { setResolved(true); setStep(2); }} className="like-button">
              👍 Like
            </button>
            <button onClick={() => { setResolved(false); setStep(2); }} className="dislike-button">
              👎 Dislike
            </button>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="feedback-step">
          <h3>Score how Evie helped you</h3>
          <div className="feedback-stars">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`star ${score >= star ? "selected" : ""}`}
                onClick={() => setScore(star)}
              >
                ★
              </span>
            ))}
          </div>
          {score > 0 && (
             <div className="feedback-step">
             <h3>Leave a comment about your experience</h3>
             <textarea
               placeholder="Write your comment here..."
               value={comment}
               onChange={(e) => setComment(e.target.value)}
             />
             <button onClick={handleSubmit} className="submit-button">
               Submit
             </button>
           </div>
          )}
        </div>
      )}

    
    </div>
  );
};

export default Feedback;
