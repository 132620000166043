import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import StarIcon from '@mui/icons-material/Star';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import LoginModal from "../../account/login";
import SignUpModal from "../../account/signup";


import setFilePhoto from "../../../images/set_file_photo.svg";
import AdditionalFiles from "../../../images/additionalFiles.svg";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { blue } from "@mui/material/colors";
import { Button as ActionButton } from "../../../components/common/button/button";
import PrebookConfirmationDialog from "./prebookConfirmationDialog";

import useSegment from "../../../hooks/useSegment";

import CaseService from "../../../services/case.service";
import {
  Alert,
  ButtonBase,
  Chip,
  Grid,
  Link,
  Portal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ProceedToOrderModal from "../treatmentSelectModal/proceedToOrderModal";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import {
  brandBlue,
  brandColor,
  brandColorHover,
  brandLight,
} from "../../../constants";
import RoundedSkeleton from "../../common/skeleton/roundedSkeleton";
import { getRandomInt } from "../../../helpers/getRandomInt";
import BannerBackground from "../../../images/hub_banner_background.png";
import { useTheme } from "@emotion/react";
import { searchTerms } from "./searchTerms";
import AdminService from "../../../services/admin.service";
import PrebookDialog from "./prebookDialog";
import { openSnackbar, openPrebookDialog } from "../../../redux/actions";

import LoadingModal from "../../common/modal/loadingModal";
import NoAccessPrepayModal from "../../../components/account/noAccessModal";
import ProceedToOrderDialog from "./proceedToOrderDialog";
import { allOnXData } from "../../../constants";
//import SectionBranding from "../../coBranding/sectionBranding";

function SelectCases({
  treatments,
  patientDetails,
  handleNextStep,
  additionalOrderDetails,
  onUpdatingProductsToShow,
  productsToShow,
  onClickAboutTreatment
}) {
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(
    "00000000-0000-0000-0000-000000000000"
  );
  


  const segment = useSegment();

  const [categoriesToShow, setCategoriesToShow] = React.useState([]);
  const [productsList, setProductsList] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const [searchFilter, setSeearchFilter] = React.useState("");
  const [selectedTreatment, setSelectedTreatment] = React.useState([]);

  //login modal
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);
  const [treatmentModalOpen, setTreatmentModalOpen] = React.useState(false);

  const navigate = useNavigate();
  const { prebookId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fetchingPrebook, setFetchingPrebook] = useState(false);
  const treatment = searchParams.get("treatment");
  const [preOrderOpen, setPreOrderOpen] = useState(false);
  const dispatch = useDispatch();

  const _loginUrl = process.env.REACT_APP_ACCOUNT_LOGIN + window.location.href;

  const [prebookConfirmationDialog, setPrebookConfirmationDialog] =
    React.useState(false);
  const theme = useTheme();

  const userInfo = useSelector((state) => state.userInfo) || {};
  const [openNoAccessModal, setOpenNoAccessModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isDentistRole, setIsDentistRole] = React.useState(false);
  const [checkStatusResult, setCheckStatusResult] = React.useState("");
  const [trendingProducts,setTrendingProducts] = useState(null);
  const [allOnXProduct, setAllonXProduct] = useState(allOnXData);
  const [isProceedToOrderModalOpen,setIsProceedToOrderModalOpen] = useState(false);

  const handleNoAccessModalClose = (confirm) => {
    setOpenNoAccessModal(false);
    if (checkStatusResult === "promptPrepay") {
      setTreatmentModalOpen(true); // allow to process
    } else {
      setSelectedTreatment([]);
    }
    
  };

  const prebookDialogisOpen = useSelector(state => state.openPrebookDialog);

  const [sectionBrand, setSectionBrand] = useState();  

  const setSectionBrandFold=() => {
       
    let siteSettings = JSON.parse(localStorage.getItem("siteSettings"));
    console.log(siteSettings);

    let siteLink = siteSettings.find((x) => x.hostUrl === "digitalarches.evidentdigital.com/");
    console.log("siteLink", siteLink);
    if (siteLink !== null && siteLink !== undefined) {
      setSectionBrand(
        <Stack spacing={7} paddingLeft={0} paddingRight={4}>
        <Box width="100%">
          <Box sx={{
                minWidth: { xs: "100%", md: "464px", lg:"100%" },
                marginBottom: 3.75,
              }}>
            <Grid container spacing={3.75} alignItems="stretch">
              <Box sx={{ width: "100%"}}>
                <Box margin={3.75}>
                  <Typography
                    variant="h5"
                    component="h2"
                    display="inline"
                    sx={{
                      color: "#000",
                      fontSize: "25px",
                    }}
                    fontWeight={700}
                  >
                    Optisplint Workflow
                  </Typography>
                </Box>
                <Divider sx={{ marginBottom: 1, marginLeft: 3.75, color: "#d5d5d5", width: '100%' }} />
                <Stack spacing={7} marginBottom={3.75}>
                  <Grid container spacing={3.75} alignItems="stretch" marginBottom={3}>
                  {allOnXProduct.map((product, index) => (<Grid key={index} item lg={4} md={6} xs={12}>
                        <Stack
                          key={index}
                          justifyContent="space-between"
                          sx={{
                            height: "100%",
                            paddingY: 3,
                            paddingX: 5.25,
                            backgroundColor: brandLight,
                            borderRadius: "24px",
                            boxSizing: "border-box",
                            boxShadow: "0px 13px 30px rgba(138, 138, 138, 0.1)",
                            backdropFilter: "blur(10px)",
                            overflow: 'hidden'
                          }}
                        >
                         
                          <Stack
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                maxHeight: "334px",
                                borderRadius: "22px",
                              }}
                            >
                              <Box
                                component="img"
                                src={product.imageLink}
                                sx={{
                                  objectFit: "contain",
                                  maxWidth: "100%",
                                  borderRadius: "22px",
                                }}
                               
                              />
                             
                            </Box>
                            <Stack>
                              <Typography
                                sx={{
                                  marginTop: 3,
                                  fontSize: "1.125rem",
                                  fontWeight: 700,
                                  color: "#000",
                                }}
                              >
                            {product.description}
                              </Typography>
                              <Divider
                                sx={{
                                  width: "22px",
                                  marginY: "12px",
                                  border: "1px solid #000",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "0.938rem",
                                  fontWeight: 400,
                                }}
                              >
                              {product.productDescription} 
                              </Typography>
                            </Stack>
                          </Stack>
                       
                          <ButtonBase
                                disableRipple
                                sx={{
                                  width: "100%",
                                  marginTop: 2,
                                  paddingY: 2,
                                  color: "#fff",
                                  borderRadius: "30px",
                                  backgroundColor: brandColor,
                                  cursor:
                                    selectedTreatment.find(
                                      (x) => x.description === product.description
                                    ) === undefined
                                      ? "pointer"
                                      : "not-allowed",
                                  ...(Boolean(
                                    selectedTreatment.find(
                                      (x) => x.description === product.description
                                    )
                                  ) && {
                                    color: brandColor,
                                    backgroundColor: "none",
                                    border: `1px solid ${brandColor}`,
                                  }),
                                  transition: "all 250ms ease",
                                  ":hover": {
                                    ...(!Boolean(
                                      selectedTreatment.find(
                                        (x) =>
                                          x.description === product.description
                                      )
                                    )
                                      ? {
                                          backgroundColor: brandColorHover,
                                        }
                                      : { filter: "brightness(90%)" }),
                                  },
                                }}
                                onClick={
                                  selectedTreatment.find(
                                    (x) => x.description === product.description
                                  ) === undefined
                                    ? () => handleSelectTreatment(product)
                                    : () => {}
                                }
                              >
                                <Typography
                                  fontWeight="600"
                                  fontSize="15px"
                                  lineHeight="19px"
                                >
                                  Order Now
                                </Typography>
                              </ButtonBase>    
                        </Stack>
                    </Grid> ))}
                  </Grid>
               
                </Stack>
                
              </Box>
            </Grid>
          </Box>
        </Box>
        
       
      </Stack>
      );
    }
  };
  React.useEffect(() => {
    if (
      localStorage.getItem("siteSettings") === null ||
      localStorage.getItem("siteSettings") === "" ||
      localStorage.getItem("siteSettings").length === 0 ||
      localStorage.getItem("siteSettings") === "[]"
    ) {
      AdminService.getAppSettings()
        .then((response) => response.json())
        .then((json) => {
          localStorage.setItem("siteSettings", JSON.stringify(json));
          setSectionBrandFold();
     
        });
    } else {

      setSectionBrandFold();
    }
  }, []);

  useEffect(()=>{
    if(prebookDialogisOpen) {
      setPreOrderOpen(true)
    };

    return () => {
      dispatch(openPrebookDialog(false));
    }
  },[prebookDialogisOpen]);

  async function getRequiredFiles(treatment) {
    let reqFiles = null;
    await CaseService.getRequiredFiles([treatment])
      .then((response) => response.json())
      .then((json) => {
        if(treatment === "CEREC Crown & Bridge"){
          json[0].name = "Drag & Drop your CEREC .dxd file here";
        }
        reqFiles = json;

        reqFiles.forEach((req) => {
          req.image = setFilePhoto;
        });

        reqFiles.push({
          name: "Additional Files",
          image: AdditionalFiles,
          files: [],
          numberOfFilesUploading: 0,
        });
      });

    return reqFiles;
  }

  React.useEffect(() => {
    let mounted = true;
    if (!prebookId || !productsToShow.length > 0) {
      return;
    }

    const goToPrebook = async () => {
      // show loading prebook

      // fetch prebook from id
      const prebookResponse = await CaseService.getPrebookDetails({
        prebookId: prebookId,
      });
      const prebookData = await prebookResponse.json();
      const prebookDetails = prebookData.data;
      const patientD = prebookDetails.patient;

      const treatmentId = prebookDetails.treatmentId;
      const treat = productsToShow.find((p) => p.id === treatmentId);
      // copied from goToOrderPage
      const treatments = [treat];
      const requiredF = [];
      for (const treat of treatments) {
        var reqFiles = await getRequiredFiles(treat.description);
        reqFiles.forEach((req) => {
          requiredF.push(req);
        });
      }
      treatments.requiredFiles = getUnique(requiredF, "name");
      let patientDetailsObject = {
        Id: patientD?.id || "",
        FirstName: patientD?.firstName || "",
        LastName: patientD?.lastName || "",
        Email: patientD?.email || "",
        DateOfBirth: patientD?.dateOfBirth || "",
        PracticeId: patientD?.practiceId || "",
        PersonnelId: patientD?.personnelId || "",
        PrebookId: prebookId,
      };
      if (mounted) {
        handleNextStep(1, treatments, patientDetailsObject);
        setSearchParams({});
        setFetchingPrebook(false);
      }
    };
    setFetchingPrebook(true);
    goToPrebook();

    return () => {
      mounted = false;
    };
  }, [
    prebookId,
    productsToShow,
    patientDetails,
    handleNextStep,
    setSearchParams,
  ]);

  React.useEffect(() => {
    // copied from go to order page
    // for automatic treatment select for diagnostic wax up
    const goToTreatmentOrder = async () => {
      const treat = productsToShow.find(
        (p) => p.description.toLowerCase() === treatment
      );
      if (additionalOrderDetails?.updatedProductId) {
        treat.productId = additionalOrderDetails.updatedProductId;
      }

      if (!treat) return;
      const treats = [treat];

      let requiredF = [];
      var reqFiles = await getRequiredFiles(treat.description);
      reqFiles.forEach((req) => {
        requiredF.push(req);
      });

      treats.requiredFiles = getUnique(requiredF, "name");
      let patientDetailsObject = {
        Id: patientDetails?.Id || "",
        FirstName: patientDetails?.FirstName || "",
        LastName: patientDetails?.LastName || "",
        Email: patientDetails?.Email || "",
        DateOfBirth: patientDetails?.DateOfBirth || "",
        PracticeId: patientDetails?.PracticeId || "",
        PersonnelId: patientDetails?.PersonnelId || "",
      };
      handleNextStep(1, treats, patientDetailsObject);
    };

    if (treatment && productsToShow.length > 0) {
      goToTreatmentOrder();
      setSearchParams({});
    }
  }, [
    treatment,
    handleNextStep,
    patientDetails,
    productsToShow,
    setSearchParams,
    additionalOrderDetails,
  ]);

  React.useEffect(() => {
    let categoriesList = [
      {
        id: "00000000-0000-0000-0000-000000000000",
        name: "All",
      },
    ];

    let productsList = [];
    
    const trendingProductIds = process.env.REACT_APP_TRENDING_PRODUCT_IDS.split(',');
    console.log("trending products", trendingProductIds);
    setTrendingProducts([]);
    const trendingProductsArray = [];
    treatments.forEach((treatm) => {
      if (treatm.description !== "3D Print Files") {
        categoriesList.push({
          id: treatm.treatmentCategoryId,
          name: treatm.description,
        });

        treatm.treatmentTypes.forEach((x) => {
          let reqs = x.requirements;
          if (x.description === "Diagnostic Wax-Up" && x.version === "V3") {

            if (
              !reqs.find((it) =>
                [it.description]?.includes("Type of Restoration (Multiple)")
              )
            )
              reqs.push({
                description: "Type of Restoration (Multiple)",
                value: null,
              });

            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              // requiredFiles: diagnosticWaxupRequiredFiles, //getRequiredFiles(x.description),
              productDescription:
                "Fully visualize the true restorative needs of your patients. The goal of the diagnostic wax-up is to develop a unique, complete treatment plan to benefit the patient’s dental health.",
              productSubDescription:
                "https://evidentdigital.com/diagnostic-wax-up",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            }
            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Evident Smile Package" &&
            x.version === "V3"
          ) {
            if (
              !reqs.find((it) =>
                [it.description]?.includes("Type of Restoration (Multiple)")
              )
            )
              reqs.push({
                description: "Type of Restoration (Multiple)",
                value: null,
              });
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              // requiredFiles: evidentSmilesRequiredFiles,
              productDescription:
                "Wow your patients and increase your case acceptance with a complete tool kit that includes the digital files you need to digitally treatment plan your case.",
              productSubDescription: "https://evidentdigital.com/smile-package",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };
            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (x.description === "Crown & Bridge" && x.version === "V3") {
            if (
              !reqs.find((it) =>
                [it.description]?.includes("Type of Restoration (Multiple)")
              )
            )
              reqs.push({
                description: "Type of Restoration (Multiple)",
                value: null,
              });


            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              // requiredFiles: crownAndBridgeRequiredFiles,
              productDescription:
                "Fixed prosthetics that are used to replace your patient's damaged or missing teeth.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "All on X Implants" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "A full arch restorative solution using a minimally invasive method to provide a functional and esthetic result.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (x.description === "Aligners" && x.version === "V3") {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Aligners give you the opportunity to print in house with our planning services.",
              productSubDescription:
                "https://evidentdigital.com/evidentaligners",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Gingival Reduction Guide" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Guide to assist in the process of re-shaping the patient's existing gingiva around the teeth.",
              productSubDescription:
                "https://evidentdigital.com/evidentaligners",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Clear Retainers" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Retainers to be used after aligner therapy or to assist with other specific dental needs.",
              productSubDescription:
                "https://evidentdigital.com/evidentaligners",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
         
            }
          } else if (x.description === "Custom Tray" && x.version === "V3") {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              // requiredFiles: crownAndBridgeRequiredFiles,
              productDescription: "Customized impression tray.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Surgical Planning and Guide" &&
            x.version === "V3"
          ) {
            if (
              !reqs.find((it) =>
                [it.description]?.includes("Type of Restoration (Multiple)")
              )
            )
              reqs.push({
                description: "Type of Restoration (Multiple)",
                value: null,
              });
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              // requiredFiles: evidentSmilesRequiredFiles,
              productDescription:
                "Place your dental implants with maximum precision and predictability based off of CBCT data and intra-oral scans.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          }

          // else if (
          //   x.description === "Provisional Restorations" &&
          //   x.version === "V3"
          // ) {
          //   productsList.push({
          //     id: x.treatmentTypeId,
          //     description: x.description,
          //     productId: x.productId,
          //     imageLink: x.imageLink,
          //     price: 0,
          //     toothNumbers: [],
          //     files: [],
          //     caseNotes: "",
          //     additionalNotes: "",
          //     dueDate: "",
          //     requirements: reqs,
          //     treatmentCategoryId: treatm.treatmentCategoryId,
          //     productDescription:
          //       "Temporary prosthetic replacements while patients wait for a permanent restoration.",
          //     productSubDescription: "",
          //     treatmentCategoryDescription: treatm.description,
          //     treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
          //     serviceProviderSelected: "Design",
          //   });
          // }
          else if (x.description === "Models" && x.version === "V3") {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Models digitally designed using your patient's intra-oral scan.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Digital Complete Dentures" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Digitally designed dentures for your edentulous patients.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Standard Nightguard" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "3D printed nightguards and splints to protect your patients from bruxism or other functional needs relieving them of future dental issues.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Lingual Retainer" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "3D printed nightguards and splints to protect your patients from bruxism or other functional needs relieving them of future dental issues.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Digital Partial Dentures" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Partial dentures promise a precise fit for your partially edentulous patients.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (x.description === "Implant Crowns" && x.version === "V3") {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Fixed restorative component to be placed over an integrated dental implant.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "Photographic Case Presentation" &&
            x.version === "V3"
          ) {
            if (
              !reqs.find((it) =>
                [it.description]?.includes("Type of Restoration (Multiple)")
              )
            )
              reqs.push({
                description: "Type of Restoration (Multiple)",
                value: null,
              });

            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "A KOIS certified extra-oral evaluation procedure for predictable outcomes of smile design cases.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (x.description === "Casts" && x.version === "V3") {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Visualize your treatments better with digital reproductions of your patient's teeth and mounted on the Panadent Articulator.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };
            productsList.push(productToPush);
            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (x.description === "Devices" && x.version === "V3") {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Only the best and latest dental devices certified by world renowned Kois center.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          } else if (
            x.description === "CEREC Crown & Bridge" &&
            x.version === "V3"
          ) {
            const productToPush = {
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              imageLink: x.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: reqs,
              treatmentCategoryId: treatm.treatmentCategoryId,
              productDescription:
                "Crowns and bridges represent fixed prosthetic solutions. Let us streamline your workflow with our Cerec milling process, providing convenient chairside solutions.",
              productSubDescription: "",
              treatmentCategoryDescription: treatm.description,
              treatmentTypeServiceProviders: x.treatmentTypeServiceProviders,
              serviceProviderSelected: "Design",
            };

            productsList.push(productToPush);

            if(trendingProductIds.includes(x.treatmentTypeId)){
              trendingProductsArray.push(productToPush);
            }
          }

          
        });
      }
    });
   
    trendingProductIds.forEach(bestSellerId=>{
      trendingProductsArray.forEach(bestSeller2=>{
        if(bestSeller2.id === bestSellerId){
          setTrendingProducts(prev => {
            if(prev && prev.length){
              return [...prev,bestSeller2];
            } else {
              return [bestSeller2];
            }
          });
        }
      })
    });
    // Check if products array is not null or undefined
if (trendingProducts && Array.isArray(trendingProducts)) {
  const filteredProduct = [];
  
  for (let i = 0; i < trendingProducts.length; i++) {
    const p = trendingProducts[i];
    if (p.id === "88a1ade1-016a-47a4-890e-a2bec7743165") {
      filteredProduct.push(p);
    }
  }
  console.log("All on X Implant",filteredProduct);
  setAllonXProduct(filteredProduct);
} else{
  console.log("wee");
}
    


   
    productsList = productsList.map((product) => ({
      ...product,
      searchTerms: [product.description, ...searchTerms[product.description]],
    }));

    setProductsList(productsList.sort((a, b) => a.orderNumber - b.orderNumber));
    onUpdatingProductsToShow(productsList);

    setCategories(categoriesList);
  }, [treatments]);

  React.useEffect(() => {
    let unique = [
      ...new Set(
        productsToShow.map((item) => item.treatmentCategoryDescription)
      ),
    ];

    setCategoriesToShow(unique);
  }, [productsToShow]);

  const selectCategory = (cat) => {
    setSelectedCategory(cat);

    if (cat !== "00000000-0000-0000-0000-000000000000") {
      let getProducts = treatments.find((x) => x.treatmentCategoryId === cat);

      if (typeof getProducts !== "undefined") {
        let productsList = [];

        getProducts.treatmentTypes.forEach((x) => {
          if (x.description === "Diagnostic Wax-Up") {
            productsList.push({
              id: x.treatmentTypeId,
              isAddOn: false,
              description: x.description,
              productId: x.productId,
              price: 0,
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              treatmentCategoryId: cat,
            });
          }
        });

        onUpdatingProductsToShow(productsList);
      }
    } else {
      onUpdatingProductsToShow(productsList);
    }
  };

  const handleSearchKeyDown = (event) => {
    const searchText = searchField;
    let productsToShowList = [];
    let productNames = [];
    if (event.key === "Enter") {
      setSeearchFilter(searchText);
      if (searchText === "") {
        selectCategory(selectedCategory);
      } else if (searchText.toLowerCase() === "graphy") {
        productsToShowList = Object.assign([], productsList);
        productsToShowList = productsToShowList.filter(
          (product) =>
            product.searchTerms.some(
              (term) => term.toLowerCase().indexOf("aligner") > -1
            ) ||
            product.searchTerms.some(
              (term) => term.toLowerCase().indexOf("retainer") > -1
            )
        );

        // productsToShowList.forEach(product=>{
        //   productNames.add(product.name);
        // });

        onUpdatingProductsToShow(productsToShowList);

        // useSegment('hub_submit_search_term',{
        //   properties: {
        //     keyword:  searchText,
        //     result: productNames
        //   }
        // })
      } else if (
        searchText.toLowerCase() === "graphy aligner" ||
        searchText.toLowerCase() === "graphy aligners" ||
        searchText.toLowerCase() === "graphy aligner planning"
      ) {
        productsToShowList = Object.assign([], productsList);
        productsToShowList = productsToShowList.filter((product) =>
          product.searchTerms.some(
            (term) => term.toLowerCase().indexOf("aligner") > -1
          )
        );

        onUpdatingProductsToShow(productsToShowList);
      } else if (
        searchText.toLowerCase() === "graphy retainer" ||
        searchText.toLowerCase() === "graphy retainers" ||
        searchText.toLowerCase() === "graphy clear retainers"
      ) {
        productsToShowList = Object.assign([], productsList);
        productsToShowList = productsToShowList.filter((product) =>
          product.searchTerms.some(
            (term) => term.toLowerCase().indexOf("retainer") > -1
          )
        );

        onUpdatingProductsToShow(productsToShowList);
      } else {
        productsToShowList = Object.assign([], productsList);
        productsToShowList = productsToShowList.filter((product) =>
          product.searchTerms.some(
            (term) => term.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          )
        );

        onUpdatingProductsToShow(productsToShowList);
      }

      
      productsToShowList.forEach((product) => {
        productNames.push(product.description);
      });

      let payload = {
        event: "hub_submit_search_term",
        properties: {
          keyword: searchText,
          results: productNames,
        },
      };

      segment.trigger(payload);
    }
  };

  const handleLoginModalClose = async (confirm) => {
    setLoginModalOpen(false);
    if (confirm === "signup") {
      let requiredF = [];
      for (const treat of selectedTreatment) {
        var reqFiles = await getRequiredFiles(treat.description);
        reqFiles.forEach((req) => {
          requiredF.push(req);
        });
      }
      const t = [...selectedTreatment];
      t.requiredFiles = getUnique(requiredF, "name");
      navigate("/signup", {
        state: {
          selectedTreatment: t,
        },
      });
      // setSignUpModalOpen(true);
    } else {
      if (confirm) {
        goToOrderForm();
      }
    }
  };

  const handleSignUpModalClose = (confirm) => {
    setSignUpModalOpen(false);

    if (confirm) {
      goToOrderForm();
    }
  };

  const handleSelectTreatment = (treatment) => {
    let checkIfLoggedIn = localStorage.getItem("auth_token");
    if (
      typeof checkIfLoggedIn === "undefined" ||
      checkIfLoggedIn == null ||
      !userInfo.Id
    ) {
      window.localStorage.clear();
      window.location.href = _loginUrl;
    } else {
      selectedTreatment.push(treatment);
      setSelectedTreatment(selectedTreatment);

      let payload = {
        event: "hub_select_treatment",
        properties: {
          product_name: treatment.description,
          product_category: treatment.treatmentCategoryDescription,
        },
      };

      segment.trigger(payload);

      let locBillingOption = localStorage.getItem("locBillingOption");
      let isDentist = userInfo.Roles.includes("Dentist");
      setIsDentistRole(isDentist);

      if (locBillingOption === "Prepay" && isDentist) {
        // if dentist is prepay check payment status
        AdminService.checkPrepayStatus(userInfo.Id)
          .then((response) => response.json())
          .then((json) => {
            if (json.error === null) {
              if (json.data !== null) {
                setCheckStatusResult(json.data.access);
                if (json.data.access !== "allowed") {
                  setOpenNoAccessModal(true);
                  // do not allow to download case
                  setErrorMessage(json.data.message);

                  setTreatmentModalOpen(false);
                } else {
                  setTreatmentModalOpen(true);
                }
              } else {
                setTreatmentModalOpen(true);
              }
            } else {
              setTreatmentModalOpen(true);
            }
          });
      } else {
        setTreatmentModalOpen(true);
      }
    }
  };

  const handleSelectTreatmentClose = (confirm) => {
    setTreatmentModalOpen(false);
    segment.trigger({
      event: "hub_proceed_to_order",
      properties: {
        products: selectedTreatment.map((treatment) => {
          return {
            product_name: treatment.description,
            product_category: treatment.treatmentCategoryDescription,
          };
        }),
      },
    });
    if (confirm) {
      let checkIfLoggedIn = localStorage.getItem("auth_token");
      if (
        typeof checkIfLoggedIn === "undefined" ||
        checkIfLoggedIn == null ||
        !userInfo.Id
      ) {
        setLoginModalOpen(true);
      } else {
        goToOrderForm();
      }
    }
  };

  const handleSelectTreatmentCancel = () => {
    setTreatmentModalOpen(false);
    setSelectedTreatment([]);
  };

  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const goToOrderForm = async () => {
    // duplicated in treatment and prebook use effect
    let requiredF = [];
    for (const treat of selectedTreatment) {
      var reqFiles = await getRequiredFiles(treat.description);
      reqFiles.forEach((req) => {
        requiredF.push(req);
      });
    }
    selectedTreatment.requiredFiles = getUnique(requiredF, "name");
    let patientDetailsObject = {
      Id: patientDetails?.Id || "",
      FirstName: patientDetails?.FirstName || "",
      LastName: patientDetails?.LastName || "",
      Email: patientDetails?.Email || "",
      DateOfBirth: patientDetails?.DateOfBirth || "",
      PracticeId: patientDetails?.PracticeId || "",
      PersonnelId: patientDetails?.PersonnelId || "",
    };
    handleNextStep(1, selectedTreatment, patientDetailsObject);
  };

  const skeletons = useMemo(() => {
    const categories = new Array(5).fill(0);
    return categories.map(() => {
      return new Array(getRandomInt(3, 6)).fill(0);
    });
  }, [productsList]);


  const handlePrebook = async (
    patient,
    date,
    scheduleCallWithManager,
    scheduleCallWithSupport,
    product
  ) => {
    const prebookDetails = {
      patientId: patient.id,
      treatmentTypeId: product.id,
      date,
      scheduleCallWithSupport,
      scheduleCallWithManager,
    };

    try {
      const resp = await CaseService.createPrebook(prebookDetails);
      if (!resp.ok) {
        const json = await resp.json();
        if (json?.error && json?.error?.length > 0) {
          
          throw new Error(json?.error[0]);
        } else {
          throw new Error("Failed to process request");
        }
      }
      setPreOrderOpen(false);

      let payload = {
        event: "hub_prebook_submit",
        properties: {
          product_name: product.description,
          product_category: product.treatmentCategoryDescription,
        },
      };

      segment.trigger(payload);

      setPrebookConfirmationDialog(true);
    } catch (err) {
      dispatch(
        openSnackbar({
          type: "error",
          title: "Something went wrong",
          body: err.message,
        })
      );
    }
  };

  const closeProceedToOrderModalHandler = (confirm) => {
    setIsProceedToOrderModalOpen(false);
    if(confirm){
      goToOrderForm();
    }
  }

  const loggedIn =
    Object.keys(userInfo).length > 0 &&
    Boolean(localStorage.getItem("auth_token")) === true;

  return (
    <div>
      <NoAccessPrepayModal
        isOpen={openNoAccessModal}
        msg={errorMessage}
        handleClose={handleNoAccessModalClose}
        isDentist={isDentistRole}
      />

      <ProceedToOrderModal
        isOpen={treatmentModalOpen}
        handleClose={handleSelectTreatmentClose}
        handleCancel={handleSelectTreatmentCancel}
        selectedTreatment={selectedTreatment}
      />
      <LoginModal isOpen={loginModalOpen} handleClose={handleLoginModalClose} />
      {/* Loading modals for prebook */}
      {productsToShow.length === 0 && Boolean(prebookId) && (
        <LoadingModal open={true} message="Loading products" />
      )}
      {fetchingPrebook && (
        <LoadingModal open={true} message="Loading prebook details" />
      )}
      {/*  */}
      <SignUpModal
        isOpen={signUpModalOpen}
        handleClose={handleSignUpModalClose}
      />
      {loggedIn && (
        <PrebookDialog
          open={preOrderOpen}
          products={productsList.filter(
            (p) =>
              ![
                "aligners",
                "clear retainers",
                "surgical planning and guide",
                "digital complete dentures",
                "digital partial dentures",
                "evident smile package",
              ].includes(p.description.toLowerCase())
          )}
          onClose={(product) => {
            let payload = {
              event: "hub_prebook_cancel",
              properties: {},
            };

            let filteredProduct;
            if (product) {
              filteredProduct = productsList.filter(
                (p) => p.description.toLowerCase() === product.toLowerCase()
              );
              if (filteredProduct) {
                payload.properties = {
                  product: {
                    product_name: filteredProduct[0].description,
                    product_category:
                      filteredProduct[0].treatmentCategoryDescription,
                  },
                };
              }
            }

            segment.trigger(payload);
            setPreOrderOpen(false);
          }}
          onSubmit={(values) => console.log(values)}
          onOrder={handlePrebook}
        />
      )}
      {prebookConfirmationDialog === true ? (
        <PrebookConfirmationDialog
          open={prebookConfirmationDialog}
          onClose={() => setPrebookConfirmationDialog(false)}
        />
      ) : (
        ""
      )}

      {/* <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "300px",
          color: "#fff",
          backgroundColor: "#101010",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "300px",
            position: "absolute",
            backgroundImage: `url(${BannerBackground})`,
            backgroundSize: "cover",
            backgroundBlendMode: "darken",
            backgroundAttachment: "scroll",
            backgroundPosition: "center bottom",
            paddingBottom: "22vw",
            backgroundClip: "content-box",
            mixBlendMode: "plus-lighter",
          }}
        />
        <Container
          maxWidth="lg"
          disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
              textAlign: "left",
              width: "100%",
              height: "300px",
              maxWidth: "560px",
            }}
          >
           <Typography
              component="h1"
              fontSize="45px"
              lineHeight="45px"
              fontWeight={600}
            >
              Welcome to
              <br />
              Evident Hub
            </Typography>
            <Typography fontWeight="500" marginTop="18px" fontSize="15px">
              To get started please select a treatment below.
            </Typography>
            <Box sx={{ paddingTop: "32px" }}>
              <TextField
                id="search-treatments"
                label=""
                variant="outlined"
                placeholder="Search Treatment..."
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                onKeyDown={handleSearchKeyDown}
                size="small"
                InputProps={{
                  sx: {
                    fontSize: "15px",
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingRight: 2.5 }}
                      onClick={(e) => handleSearchKeyDown({ key: "Enter" })}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="medium"
                        sx={{
                          backgroundColor: brandColor,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: brandColorHover,
                          },
                          padding: "4px",
                        }}
                      >
                        <KeyboardArrowRightIcon fontSize="20px" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: "#fff",
                    borderRadius: "60px",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    boxShadow: "0px 10px 30px rgba(115, 111, 111, 0.15)",
                    backdropFilter: "blur(10px)",
                  },
                }}
                sx={[
                  {
                    borderColor: "#fff",
                    width: "100%",
                    maxWidth: "360px",
                  },
                  (theme) => ({
                    "&:active": {
                      borderColor: "#000",
                    },
                  }),
                ]}
              />
            </Box>
          </Box>
        </Container>
      </Box> */}

      <Container
        sx={{ marginY: "35px" }}
        maxWidth="lg"
        disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
      >
        {/* <Box sx={{display:'flex', justifyContent:'center', flexWrap: "wrap"}}>
                    {categories.map((cat, index) => (
                        <Box sx={{minWidth: '50px', textAlign:'center', padding: '15px', marginRight: categories.length === index + 1 ? '0px' : '45px', cursor:'pointer',  marginTop: '15px', background: cat.id === selectedCategory ? "#DEE1E2" : "unset", boxShadow: cat.id === selectedCategory ? "inset 0px 4px 4px rgba(0, 0, 0, 0.15)" : "unset", borderRadius: cat.id === selectedCategory ? "7px": "unset"}} onClick={() => selectCategory(cat.id)}>
                            {cat.name}
                        </Box>
                    ))}
                </Box> */}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          jsx={{ marginTop: "30px", marginBottom: "32px" }}
        >
          {patientDetails && patientDetails.Id !== "" && (
            <Alert
              iconMapping={{
                success: <NotificationsIcon fontSize="inherit" />,
              }}
              sx={{ marginY: 2, width: "max-content", borderRadius: "22px" }}
            >
              Adding a new treatment for{" "}
              <strong>
                {patientDetails.FirstName} {patientDetails.LastName}
              </strong>
              {" - "}
              <Link
                component={RouterLink}
                to={"/patients/" + patientDetails.Id}
              >
                Go to patient page
              </Link>
            </Alert>
          )}
        </Stack>

        {/* {!Boolean(patientDetails?.PrebookId) && loggedIn && (
          <Box
            sx={{
              border: "1px solid #D9D9D9",
              borderRadius: "20px",
              padding: 3.375,
              marginBottom: 4.5,
            }}
          >
            <Stack
              maxWidth="630px"
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "0 auto" }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "1.563rem" }}>
                Pre-book your same-day case
              </Typography>
              <Typography textAlign="center" marginTop={1.25}>
                Schedule your case now to align with your patient appointment
              </Typography>
              <ActionButton
                sx={{ marginTop: 2.25 }}
                onClick={() => {
                  segment.trigger({
                    event: "hub_prebook_button",
                  });
                  setPreOrderOpen(true);
                }}
                disabled={productsToShow.length === 0}
              >
                Book Now
              </ActionButton>
            </Stack>
          </Box>
        )} */}

        {searchFilter !== "" && (
          <Box
            sx={{
              marginTop: 2,
              marginBottom: 3,
            }}
          >
            <Typography
              component={"h2"}
              sx={{
                fontWeight: 700,
                fontSize: "32px",
                color: "#000",
              }}
            >
              Showing {productsToShow.length} result
              {productsToShow.length > 1 ? "s" : ""} for{" "}
              <Box component="span" sx={{ fontStyle: "italic" }}>
                {searchFilter}
              </Box>
            </Typography>
          </Box>
        )}
        {/* Wee */}
        {sectionBrand}      
        {trendingProducts && !searchFilter && <Stack spacing={7} paddingLeft={0} paddingRight={4}>
          <Box width="100%">
            <Box sx={{
                  minWidth: { xs: "100%", md: "464px" },
                  marginBottom: 3.75,
                }}>
              <Grid container spacing={3.75} alignItems="stretch">
                <Box>
                  <Box margin={3.75}>
                    <Typography
                      variant="h5"
                      component="h2"
                      display="inline"
                      sx={{
                        color: "#000",
                        fontSize: "25px",
                      }}
                      fontWeight={700}
                    >
                      Trending Products
                    </Typography>
                  </Box>
                  {/* <Divider sx={{ marginBottom: 1, marginLeft: 3.75, color: "#d5d5d5", width: '100%' }} /> */}
                  <Stack spacing={7} marginBottom={3.75}>
                    <Grid container spacing={3.75} alignItems="stretch" marginBottom={3}>
                      {trendingProducts.map((product,index)=><Grid key={product.id} item lg={4} md={6} xs={12}>
                          <Stack
                            justifyContent="space-between"
                            sx={{
                              height: "100%",
                              paddingY: 3,
                              paddingX: 5.25,
                              backgroundColor: brandLight,
                              borderRadius: "24px",
                              boxSizing: "border-box",
                              boxShadow: "0px 13px 30px rgba(138, 138, 138, 0.1)",
                              backdropFilter: "blur(10px)",
                              overflow: 'hidden'
                            }}
                          >
                            <Stack sx={{display: 'flex', alignItems: 'end'}}>
                              <div style={{
                                  left: '15px',
                                  top: '15px',
                                  position:'absolute',
                                  // transform: 'rotate(45deg)',
                                  width:'20%',
                                  padding: '5px',
                                  backgroundColor: '#0A1810',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius:'20px'
                              }}>
                                <Typography sx={{color:'#FFF', fontWeight:'bold'}}><StarIcon sx={{ color: 'yellow', fontSize: '16px', marginRight: '5px'}}/>{`No. ${index+1}`}</Typography>                          
                              </div>
                            </Stack>
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  maxHeight: "334px",
                                  borderRadius: "22px",
                                }}
                              >
                                <Box
                                  component="img"
                                  src={product.imageLink}
                                  sx={{
                                    objectFit: "contain",
                                    maxWidth: "100%",
                                    borderRadius: "22px",
                                  }}
                                />
                              </Box>
                              <Stack>
                                <Typography
                                  sx={{
                                    marginTop: 3,
                                    fontSize: "1.125rem",
                                    fontWeight: 700,
                                    color: "#000",
                                  }}
                                >
                                  {product.description}
                                </Typography>
                                <Divider
                                  sx={{
                                    width: "22px",
                                    marginY: "12px",
                                    border: "1px solid #000",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "0.938rem",
                                    fontWeight: 400,
                                  }}
                                >
                                  {product.productDescription}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Box>
                              {/* {true && ( */}
                              {searchFilter !== "" &&
                                productsToShow.length !== 17 && (
                                  <Box
                                    sx={{
                                      marginTop: 4,
                                      marginBottom: 2,
                                    }}
                                  >
                                    <Chip
                                      label="Associated"
                                      size="small"
                                      color="brandBlue"
                                      sx={{
                                        color: blue[50],
                                        borderRadius: "5px",
                                      }}
                                    />
                                    
                                  </Box>
                                )}

                              
                                <Button
                                  disableRipple
                                  sx={{
                                    width: "100%",
                                    marginTop: 3,
                                    paddingY: 2,
                                    color: '#0A1810',
                                    borderRadius: "30px",
                                    backgroundColor: "white",
                                    borderColor: '#0A1810',
                                  }}
                                  variant="outlined"
                                  onClick={() =>
                                    onClickAboutTreatment(true, product)
                                  }
                                >
                                  <Typography
                                    fontWeight="600"
                                    fontSize="15px"
                                    lineHeight="19px"
                                  >
                                    About Treatment
                                  </Typography>
                                </Button>
                              

                              <ButtonBase
                                disableRipple
                                sx={{
                                  width: "100%",
                                  marginTop: 2,
                                  paddingY: 2,
                                  color: "#fff",
                                  borderRadius: "30px",
                                  backgroundColor: brandColor,
                                  cursor:
                                    selectedTreatment.find(
                                      (x) => x.description === product.description
                                    ) === undefined
                                      ? "pointer"
                                      : "not-allowed",
                                  ...(Boolean(
                                    selectedTreatment.find(
                                      (x) => x.description === product.description
                                    )
                                  ) && {
                                    color: brandColor,
                                    backgroundColor: "none",
                                    border: `1px solid ${brandColor}`,
                                  }),
                                  transition: "all 250ms ease",
                                  ":hover": {
                                    ...(!Boolean(
                                      selectedTreatment.find(
                                        (x) =>
                                          x.description === product.description
                                      )
                                    )
                                      ? {
                                          backgroundColor: brandColorHover,
                                        }
                                      : { filter: "brightness(90%)" }),
                                  },
                                }}
                                onClick={
                                  selectedTreatment.find(
                                    (x) => x.description === product.description
                                  ) === undefined
                                    ? () => handleSelectTreatment(product)
                                    : () => {}
                                }
                              >
                                <Typography
                                  fontWeight="600"
                                  fontSize="15px"
                                  lineHeight="19px"
                                >
                                  Order Now
                                </Typography>
                              </ButtonBase>
                            </Box>
                          </Stack>
                      </Grid>)}
                    </Grid>
                  </Stack>
                  
                </Box>
              </Grid>
            </Box>
          </Box>
          
         
        </Stack>}
        <Stack spacing={7}>
          {!categoriesToShow.length > 0 && searchFilter === "" && (
            <>
              {skeletons.map((row, i) => (
                <Box key={i}>
                  <RoundedSkeleton
                    height="64px"
                    sx={{
                      width: { xs: "100%", md: "464px" },
                      marginBottom: 5,
                    }}
                  />
                  <Box>
                    <Grid container spacing={3.75}>
                      {row.map((item, i) => (
                        <Grid key={i} item lg={4} md={6} xs={12}>
                          <RoundedSkeleton
                            sx={{
                              boxSizing: "border-box",
                              height: { xs: "580px", md: "590px", lg: "530px" },
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              ))}
            </>
          )}
          
          {categoriesToShow.map((category) => (
            <Box width="100%" key={category}>
              <Box
                sx={{
                  minWidth: { xs: "100%", md: "464px" },
                  marginBottom: 3.75,
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  display="inline"
                  sx={{
                    color: "#000",
                    fontSize: "25px",
                  }}
                  fontWeight={700}
                >
                  {category}
                </Typography>
              </Box>
              {/* <Divider sx={{ marginBottom: 5, color: "#d5d5d5" }} /> */}
              <Box>
                <Grid container spacing={3.75} alignItems="stretch">
                   {productsToShow
                    .filter((x) => x.treatmentCategoryDescription === category)
                    .map((product) => (
                      <Grid key={product.id} item lg={4} md={6} xs={12}>
                        <Stack
                          justifyContent="space-between"
                          sx={{
                            height: "100%",
                            paddingY: 3,
                            paddingX: 5.25,
                            backgroundColor: brandLight,
                            borderRadius: "24px",
                            boxSizing: "border-box",
                            boxShadow: "0px 13px 30px rgba(138, 138, 138, 0.1)",
                            backdropFilter: "blur(10px)",
                          }}
                        >
                          <Stack
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                maxHeight: "334px",
                                borderRadius: "22px",
                              }}
                            >
                              <Box
                                component="img"
                                src={product.imageLink}
                                sx={{
                                  objectFit: "contain",
                                  maxWidth: "100%",
                                  borderRadius: "22px",
                                }}
                              />
                            </Box>
                            <Stack>
                              <Typography
                                sx={{
                                  marginTop: 3,
                                  fontSize: "1.125rem",
                                  fontWeight: 700,
                                  color: "#000",
                                }}
                              >
                                {product.description}
                              </Typography>
                              <Divider
                                sx={{
                                  width: "22px",
                                  marginY: "12px",
                                  border: "1px solid #000",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "0.938rem",
                                  fontWeight: 400,
                                }}
                              >
                                {product.productDescription}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Box>
                            {/* {true && ( */}
                            {searchFilter !== "" &&
                              productsToShow.length !== 17 && (
                                <Box
                                  sx={{
                                    marginTop: 4,
                                    marginBottom: 2,
                                  }}
                                >
                                  <Chip
                                    label="Associated"
                                    size="small"
                                    color="brandBlue"
                                    sx={{
                                      color: blue[50],
                                      borderRadius: "5px",
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      sx={{
                                        marginTop: 0.75,
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {product.searchTerms.find(
                                        (term) =>
                                          term
                                            .toLowerCase()
                                            .indexOf(
                                              searchFilter.toLowerCase()
                                            ) > -1
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}

                            
                              <Button
                                disableRipple
                                sx={{
                                  width: "100%",
                                  marginTop: 3,
                                  paddingY: 2,
                                  color: '#0A1810',
                                  borderRadius: "30px",
                                  backgroundColor: "white",
                                  borderColor: '#0A1810',
                                }}
                                variant="outlined"
                                onClick={() =>
                                  onClickAboutTreatment(true, product)
                                }
                              >
                                <Typography
                                  fontWeight="600"
                                  fontSize="15px"
                                  lineHeight="19px"
                                >
                                  About Treatment
                                </Typography>
                              </Button>
                            

                            <ButtonBase
                              disableRipple
                              sx={{
                                width: "100%",
                                marginTop: 2,
                                paddingY: 2,
                                color: "#fff",
                                borderRadius: "30px",
                                backgroundColor: brandColor,
                                cursor:
                                  selectedTreatment.find(
                                    (x) => x.description === product.description
                                  ) === undefined
                                    ? "pointer"
                                    : "not-allowed",
                                ...(Boolean(
                                  selectedTreatment.find(
                                    (x) => x.description === product.description
                                  )
                                ) && {
                                  color: brandColor,
                                  backgroundColor: "none",
                                  border: `1px solid ${brandColor}`,
                                }),
                                transition: "all 250ms ease",
                                ":hover": {
                                  ...(!Boolean(
                                    selectedTreatment.find(
                                      (x) =>
                                        x.description === product.description
                                    )
                                  )
                                    ? {
                                        backgroundColor: brandColorHover,
                                      }
                                    : { filter: "brightness(90%)" }),
                                },
                              }}
                              onClick={
                                selectedTreatment.find(
                                  (x) => x.description === product.description
                                ) === undefined
                                  ? () => handleSelectTreatment(product)
                                  : () => {}
                              }
                            >
                              <Typography
                                fontWeight="600"
                                fontSize="15px"
                                lineHeight="19px"
                              >
                                Order Now
                              </Typography>
                            </ButtonBase>
                          </Box>
                        </Stack>
                      </Grid>
                    )) }
                  
                </Grid>
              </Box>
            </Box>
          ))}
        </Stack>
        <Portal>
          <ProceedToOrderDialog
            isOpen={isProceedToOrderModalOpen}
            onClose={closeProceedToOrderModalHandler}
            treatmentDescriptions={selectedTreatment.map(t=>t.description)}
          />
        </Portal>
      </Container>
    </div>
  );
}

export default connect(null, null)(SelectCases);
