import {
  INIT_USERINFO,
  UPDATE_DISPLAYTHEME,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  OPEN_LOADING,
  CLOSE_LOADING,
  UPDATE_PATIENTID,
  UPDATE_PRODUCT_TO_ADD,
  UPDATE_USE_CURRENT_SCAN,
  UPDATE_PATIENT,
  OPEN_PREBOOK_DIALOG,
  SET_SESSION_ID
} from "../constants/index";

export function initUser(payload) {
  return { type: INIT_USERINFO, payload };
}

export function updateDisplayTheme(payload) {
  return { type: UPDATE_DISPLAYTHEME, payload };
}

export function openSnackbar(payload) {
  return { type: OPEN_SNACKBAR, payload };
}

export function closeSnackbar() {
  return { type: CLOSE_SNACKBAR };
}

export function openLoading() {
  return { type: OPEN_LOADING };
}

export function closeLoading() {
  return { type: CLOSE_LOADING };
}

export function updatePatient(payload) {
  return { type: UPDATE_PATIENT, payload };
}

export function updatePatientId(payload) {
  return { type: UPDATE_PATIENTID, payload };
}

export function updateProductToAdd(payload) {
  return { type: UPDATE_PRODUCT_TO_ADD, payload };
}

export function updateUseCurrentScan(payload) {
  return { type: UPDATE_USE_CURRENT_SCAN, payload };
}

export function openPrebookDialog(payload) {
  return { type: OPEN_PREBOOK_DIALOG, payload };
}

export function setSessionId(payload) {
  return { type: SET_SESSION_ID, payload };
}