import { TicketsState } from '../types';

import { createReducer } from '../../utils/createReducer';
import {
  TicketsActions,
  SET_TICKETS
} from '../actions/types';

const initialState = {
  tickets: [],
};

const ticketsReducer = {
  [SET_TICKETS]: (state: TicketsState, {tickets}) => ({ ...state, tickets: tickets }),
}

export default (state = initialState, action: TicketsActions) => createReducer(ticketsReducer, state, action);
