import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {setSessionId} from '../../redux/actions/index'
import { Widget, deleteMessages, addResponseMessage, toggleWidget, addUserMessage, renderCustomComponent, getMessages, setMessages, setTickets, isWidgetOpened } from './chat-widget/index';
import './styles.css';
import AskAiService from '../../services/askAi.service';
import { MESSAGE_SENDER } from './chat-widget/src/constants';
import Message from './chat-widget/src/components/Widget/components/MainBody/components/Messages/components/Message';
import { FeedbackPayload } from './chat-widget/src/components/Widget/components/Feedback';

const NewSessionButton = ({ handleStartNewSession }) => (
  <div
    style={{
      textAlign: 'center',
      padding: '10px',
      backgroundColor: 'none',
    }}
  >
    <button
      onClick={handleStartNewSession}
      style={{
        background: 'none',
        border: 'none',
        backgroundColor: 'none',
        color: '#007BFF',
        cursor: 'pointer',
        fontSize: '14px',
      }}
    >
      Wanna start a new session? Click here!
    </button>
  </div>
);

const AskAiChatWidget = () => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const sessionId = useSelector((state: any) => state.sessionId); // Retrieve sessionId from Redux store
  const dispatch = useDispatch(); // Get dispatch function from Redux

  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  let chatList = [];

  const getSessionId = async () => {
    let storedSessionId = localStorage.getItem('aiSessionId');
    if (storedSessionId == null || storedSessionId === undefined) {
      setIsLoading(true);
      const latestSessionFromDb = await AskAiService.getCurrentSession();
      localStorage.setItem('aiSessionId', latestSessionFromDb.toString());
      dispatch(setSessionId(latestSessionFromDb)); // Store sessionId in Redux
      setIsLoading(false);
      return latestSessionFromDb;
    } else {
      dispatch(setSessionId(parseInt(storedSessionId, 10))); // Parse as integer and store in Redux
      return parseInt(storedSessionId, 10);
    }
  };

  const handleStartNewSession = () => {
    console.log("new session toggled");
    setShowFeedback(true);
  };

  const startNewSession = async () => {
    setIsLoading(true);
    deleteMessages(); // Clear the chat widget messages
    const newSession = await AskAiService.requestNewSession();
    localStorage.setItem('aiSessionId', newSession.toString());
    dispatch(setSessionId(newSession)); 
    //await addGreetingMessage(newSession);
    setIsLoading(false);
  };

  const onSubmitFeedback = async (feedback: FeedbackPayload) => {
    const payload = {
      feedbackScore: feedback.resolved ? feedback.score : 0,
      feedbackComment: feedback.comment,
      sessionId 
    };

    await AskAiService.createFeedback(payload);
    setShowFeedback(false);
    console.log("submitted feedback");
    startNewSession();
  }

  const toggleChatWindow = () => {
    toggleWidget();
    setIsOpen((prev) => !prev); // Toggle chat window state
  };

  useEffect(() => {
    var fetchSession = async () => {
      await getSessionId();
    };
    fetchSession();

    var fetchTickets = async () => {
      var ticketsList = await AskAiService.getTickets();
      var tickets = ticketsList
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .map(t => ({
        ticketNumber: t.ticketNumber,
        status: t.ticketStatus,
        timestamp: t.updatedAt ? t.updatedAt : t.createdAt,
        resolution: t.ticketResolution
      }));
      setTickets(tickets);
    }
    fetchTickets();
   
  }, []); 

  // Get random greeting message
  const getRandomGreeting = (firstName) => {
    const greetings = [
      `Hello ${firstName}! Evie here 😊 How can I assist you today?`,
      `Hey ${firstName}, great to see you! Need a hand with something?`,
      `Hi ${firstName}, I'm Evie! What's on your mind today?`,
      `Hello there ${firstName}! What brings you here? How can I help?`,
      `Hi ${firstName}! I’m here to help however I can. What can I do for you?`,
      `Hey ${firstName}! Evie here. How’s your day going? Anything I can assist with?`,
      `Hi ${firstName}! Lovely to connect. Let me know how I can support you today.`,
      `Hi there, ${firstName}! Evie at your service 😊 What's up?`,
      `Hello ${firstName}! How’s it going? I’m here if you need anything.`,
      `Hey ${firstName}! I’m Evi—happy to help with anything on your mind!`,
      `Hi ${firstName}! I’m here and ready to assist you. What's next?`,
      `Hello ${firstName}! How’s your day shaping up? Let me know how I can help!`,
      `Hey ${firstName}, Evie here. I’m ready whenever you are!`,
      `Hi ${firstName}! Evie reporting for duty 😊 How can I assist you?`,
      `Hello ${firstName}! Need any help today? I’m here for you!`,
      `Hey ${firstName}! Hope your day is going well. What’s up?`,
      `Hi ${firstName}! Evie here, ready to lend a hand. What can I do for you?`,
      `Hello, ${firstName}! It’s Evie. What can I help you with today?`,
      `Hey there ${firstName}! How can I support you today?`,
      `Hi ${firstName}! Evie here. Anything on your mind? What’s up?`
    ];
    return greetings[Math.floor(Math.random() * greetings.length)];
  };

  // Add greeting message to chat
  const addGreetingMessage = async (generatedSession) => {
    const session = generatedSession || sessionId; // Use `generatedSession` or fallback to `sessionId`
    const greetingMessage = getRandomGreeting(userInfo.FirstName);
    await AskAiService.addGreetingMessage({
      sessionId: session,
      isSentByAi: true,
      message: greetingMessage,
      isToShow: true,
    });
  };
  // Handle message submission
  const onSubmitHandler = async (message) => {
    if (!isSubmitting && message) {
      setSubmitting(true);
      const payload = {
        sessionId: sessionId,
        isSentByAi: false,
        message: message,
        isToShow: true
      };

      try {
        const response = await AskAiService.sendToDatabase(sessionId, message);
        addUserMessage(message, response.id);
        await AskAiService.sendToAi(sessionId, userInfo.FirstName, userInfo.Email);

      } catch (error) {
        console.error("Error during API calls", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  // Handle new user message
  const handleNewUserMessage = (newMessage) => {
    onSubmitHandler(newMessage);
  };
  
  
  useEffect(() => {
    let interval;

    const fetchData = async () => {
      const response = await AskAiService.requestChat(sessionId);
      chatList = response;
      if (response.length === 0) {
        deleteMessages(); // Clear widget messages

        if (!response.some((chat) => chat.isSentByAi)) {
          console.log("no greet");
          await addGreetingMessage(null); // Add greeting if no AI messages yet
        }
      } else {
        var newMessages = [];
        var currentMessages = getMessages().filter(m => m.customId != "newSession");
          response
          .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
          .forEach((msg) => {
            const isMessageAlreadyInList = currentMessages.some((chat) => chat.customId === msg.id);
            if (!isMessageAlreadyInList && msg.isToShow) {
              
              newMessages.push({
                type: "text",
                sender: msg.isSentByAi ? MESSAGE_SENDER.RESPONSE : MESSAGE_SENDER.CLIENT,
                showAvatar: true,
                timestamp: new Date(msg.createdAt),
                unread: !isOpen,
                customId: msg.id,
                text: msg.message,
                component: Message
              });
            }
          });

        const newSessionButton = {
          type: "component",
          sender: MESSAGE_SENDER.RESPONSE,
          timestamp: new Date(),
          showAvatar: true,
          customId: "newSession",
          unread: true,
          component: NewSessionButton,
          props: {handleStartNewSession}
        };
        setMessages([...currentMessages, ...newMessages, newSessionButton])
        setIsLoading(false);
      }

    };

    const startFetching = () => {
      fetchData(); // Fetch data immediately
      interval = setInterval(fetchData, 3500); // Fetch data every 3 seconds
    };
  
    const stopFetching = () => {
      if (interval) {
        clearInterval(interval); // Clear the interval
        interval = null; // Reset the reference
      }
    };
    console.log(isWidgetOpened, isWidgetOpened())
    if (sessionId !== null && isWidgetOpened() && !isLoading && !showFeedback) {
      if (!interval) {
        startFetching(); // Start fetching only if not already running
      }
    } else {
      stopFetching(); // Stop fetching when conditions are not met
    }
  
    return () => {
      stopFetching(); // Cleanup interval on unmount or dependency change
    };
  }, [isWidgetOpened(), sessionId, isLoading, showFeedback]);  

  return (
    <div style={{ textAlign: 'center' }}>
      <button
        onClick={toggleChatWindow}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '0',
          cursor: 'pointer',
          zIndex: 10000000,
          borderRadius: '50%',
          width: '60px',
          height: '60px',
        }}
      >
        {/* Button content (e.g., icon) */}
      </button>

      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="askAi"
        subtitle={"Hi I'm Evie!\n\nTry to ask me about your cases 💭"}
        isOpen={isOpen}
        chatId={sessionId}
        emojis={true}
        showTimeStamp={false}
        showBadge={false}
        showCloseButton={true}
        isLoading={isLoading}
        showFeedback={showFeedback}
        onSubmitFeedback={onSubmitFeedback}
      />
    </div>
  );
};

export default AskAiChatWidget;
