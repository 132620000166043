const _smartClinicApiUrl = process.env.REACT_APP_SMARTCLINIC_API_END_POINT; // Smart Clinic API base URL
const _askAiApiUrl = process.env.REACT_APP_ASKAI_API_END_POINT;// AI API base URL
let isRequestInProgress = false;

const AskAiService = {
  
  // Request chat from the Smart Clinic API
  async requestChat(sessionId) {

    if (isRequestInProgress) {
      console.warn('A request is already in progress.');
      return null; // Or handle accordingly, e.g., queue the request
    }
  
    isRequestInProgress = true;
  
    try {
      const response = await fetch(`${_smartClinicApiUrl}/AiChat?sessionId=${sessionId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch chat data from Smart Clinic API');
      }
  
      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error('Error during API request', error);
      throw error;
    } finally {
      isRequestInProgress = false;
    }
  },

  async requestNewSession() {
    try {
      const response = await fetch(`${_smartClinicApiUrl}/AiChat/GetNewSessionId`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch chat data from Smart Clinic API');
      }

      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error('Error during API request', error);
      throw error;
    }
  },

  async getCurrentSession() {
    try {
      const response = await fetch(`${_smartClinicApiUrl}/AiChat/GetCurrentSessionId`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch chat data from Smart Clinic API');
      }

      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error('Error during API request', error);
      throw error;
    }
  },

  // Send user message to SmartClinic API
  async sendToDatabase(sessionId, message) {
    try {
      const payload = {
        sessionId: sessionId,
        isSentByAi: false,
        message: message,
        isToShow: true
      };

      const response = await fetch(`${_smartClinicApiUrl}/AiChat`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to send user message');
      }

      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error('Error during sending user message', error);
      throw error;
    }
  },

  // Send AI response to Ask AI API
  async sendToAi(sessionId, firstName, email) {
    try {
      const aiPayload = [
        // ...chatList
        //   .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort chat by timestamp
        //   .map((chat) => ({
        //     role: chat.isSentByAi ? 'assistant' : 'user',
        //     content: chat.message
        //   })),
        // {
        //   role: 'user',
        //   content: message
        // }
      ];

      const response = await fetch(`${_askAiApiUrl}/askAi?sessionId=${sessionId}&name=${encodeURIComponent(firstName ?? '')}&email=${encodeURIComponent(email ?? '')}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: JSON.stringify(aiPayload),
      });

      if (!response.ok) {
        throw new Error('Failed to get AI response');
      }

    } catch (error) {
      console.error('Error during AI response', error);
      throw error;
    }
  },

  // Handle the submission of the user message and AI response
  async handleSubmit(sessionId, chatList, message, firstName, setSubmitting) {
    if (!message) return;

    setSubmitting(true);

    try {
      // Send user message
      await this.sendUserMessage(sessionId, message);

      // Send AI response
      await this.sendAiResponse(sessionId, chatList, message, firstName);

    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setSubmitting(false);
    }
  },

  // Add a greeting message to the Ask AI API
  async addGreetingMessage(payload) {
    try {
      const response = await fetch(`${_smartClinicApiUrl}/AiChat`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to add greeting message');
      }

      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error('Error during API request', error);
      throw error;
    }
  },

  async createFeedback(payload) {
    try {
  

      const response = await fetch(`${_smartClinicApiUrl}/AiChat/ChatFeedback`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create feedback');
      }

      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error('Error during create feedback', error);
      throw error;
    }
  },

  async getTickets() {
    try {
      const response = await fetch(`${_smartClinicApiUrl}/AiChat/GetTickets`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch tickets data from Smart Clinic API');
      }

      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error('Error during API request', error);
      throw error;
    }
  },


  
};

export default AskAiService;
