export const INIT_USERINFO = "INIT_USERINFO";
export const UPDATE_DISPLAYTHEME = "UPDATE_DISPLAYTHEME";
export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const OPEN_LOADING = "OPEN_LOADING";
export const CLOSE_LOADING = "CLOSE_LOADING";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENTID = "UPDATE_PATIENTID";
export const UPDATE_PRODUCT_TO_ADD = "UPDATE_PRODUCT_TO_ADD";
export const UPDATE_USE_CURRENT_SCAN = "UPDATE_USE_CURRENT_SCAN";
export const OPEN_PREBOOK_DIALOG = "OPEN_PREBOOK_DIALOG";
export const SET_SESSION_ID = "SET_SESSION_ID";
